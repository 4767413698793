import { defaultLanguage } from "../../prismic-config"

/* The Link Resolver
* This function will be used to generate links to Prismic documents
As your project grows, you should update this function according to your routes */

const linkResolver = (doc) => {
  const properties = doc._meta || doc;
  if (properties.type === "homepage") {
    return properties.lang === defaultLanguage ? "/" : `/${properties.lang}`
  }

  if (properties.type === "page") {
    return properties.lang === defaultLanguage
      ? `/${properties.uid}`
      : `/${properties.lang}/${properties.uid}`
  }

  if (properties.type === "solution") {
    return properties.lang === defaultLanguage
      ? `/${properties.type}/${properties.uid}`
      : `/${properties.type}/${properties.lang}/${properties.uid}`
  }

  return "/"
};

export default linkResolver
